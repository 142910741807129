<template>
	<div>
		<!-- 头部logo -->
		<headLogo @navigation="navigation"></headLogo>
		
		<!-- 头部导航 -->
		<navigation :headTitle="headTitle" :headImg="headImg" style="margin-top:96px;"></navigation>
		
		<div class="values">
			<el-row class="values-title">
				{{list.title}}
			</el-row>
			<div class="values-message" v-html="bodyContent">
				{{bodyContent}}
			</div>
		</div>
		
		<!-- 菜单导航 -->
		<meauDialog :meauValue="navigationvalue" @meauFn="menu"></meauDialog>
		
		<!-- 底部导航 -->
		<bottomNavigation></bottomNavigation>
	</div>
</template>

<script>
	// 图片查询接口
	import { selectPictureListById , selectContentListById } from '@/api/index.js'
	// 头部组件
	import headLogo from '@/components/headLogo.vue'
	// 头部导航
	import navigation from '@/components/navigation.vue'
	// 底部导航
	import bottomNavigation from '@/components/bottomNavigation.vue'
	// 菜单导航
	import meauDialog from '@/components/meauDialog.vue'
	export default
	{
		data()
		{
			return{
				// 头部导航标题
				headTitle:'VALUES',
				// 头部导航图片
				headImg:'/index/knowledge.png',
				// 导航值
				navigationvalue:false,
				
				list:{},
				
				clientHeight: '',
				
				bodyContent:'',
				isshow:true,
			}
		},
		components:
		{
			headLogo,
			navigation,
			bottomNavigation,
			meauDialog,
		},
		created()
		{
			this.getValueList()
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			
			// 获取VALUE数据
			getValueList()
			{
				selectContentListById(
				{
				  chnanelType: 1,
				  isIndex: 1,
				  moduleType: 'MODULE_VALUES'
				})
				.then((response)=>
				{
					if(response.data.data=='')
					{
						this.$router.push(
						{
							path:'/error',
							item:2,
						})
					}
					else
					{
						let length=response.data.data.length-1;
						this.list=response.data.data[length];
						this.bodyContent=this.list.bodyContent;
					}
				})
			}
		}
	}
</script>

<style scoped>
	.values{
		width: 60%;
		margin-left: 20%;
		margin-top: 70px;
	}
	.values-title{
		font-size: 36px;
		color: #505050;
		line-height: 54px;
	}
	.values-message{
		width: 100%;
		line-height: 30px;
		margin-top: 61px;
		display: inline-block;
		text-align: justify;
		word-wrap:break-word;
		white-space: pre-wrap;
	}
	.values-message::v-deep img{
		max-width:100%;
	}
</style>